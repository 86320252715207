// import React from 'react';
// import { Worker, Viewer  } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import '@react-pdf-viewer/zoom/lib/styles/index.css';
// import '../../styles/App.scss';
// import HelpPdf from '../../images/paHelpLinkEn.pdf';

// const HelpPage = () => {
// const defaultLayoutPluginInstance = defaultLayoutPlugin();

//     return(
//         <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
//          {/* External PDF Viewer - CSS required  */}
//         <div
//             style={{
//                 border: '1px solid rgba(0, 0, 0, 0.3)',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 height: '100%',
//                 width: '90%',
//                 margin: 'auto'
//             }}
//         >
//         <div style={{ flex: 1, overflow: 'hidden' }}>
//             <Viewer
//             fileUrl={HelpPdf}
//             plugins={[
//                         defaultLayoutPluginInstance,
//                     ]}
//             />
//         </div>
//         </div>
//       </Worker>
//     )
// }

// export default HelpPage;


import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "../../styles/App.scss";
import HelpPdf from "../../images/paHelpLinkEn.pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const HelpPage = (props) => {
  props.allowPrefBaseRef(false)
  props.compositionMethodFlag(true)
  const layoutPluginInstance = defaultLayoutPlugin();
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <div
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "90%",
          margin: "auto",
        }}
      >
        <div style={{ display: "flex", height: "1000px" }}>
          <div style={{ flex: "1 1 70%", overflow: "auto" }}>
            <Viewer fileUrl={HelpPdf} plugins={[layoutPluginInstance]} />
          </div>
        </div>
      </div>
    </Worker>
  );
};
export default HelpPage;
